div.view {
  width: 100%;
  height: calc(100vh - 200px);
}

.mouseIconP {
  cursor: pointer;
}

.dataGridContainer {
  display: flex;
  height: 100%;
}

.orgDataGridContainer {
  display: flex;
  max-height: 85%;
  width: 100%;
  overflow-x: scroll;
}

.adminCenterToolbar {
  height: 100vh;
  width: 100%;
}

.imageViewWidth {
  width: 100%;
}

.btnText {
  text-transform: none;
}

.fixedFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  max-width: 100%;
  bottom: 0px;
  height: 60px;
  padding-top: 30px;
  border-top: 1px solid #ccc;
}

.dialogContentHeight {
  height: 600px;
}

.vcldgflexGrow {
  flex-grow: 1;
}
